import profile from '@/api/profile'
import serviceDetail from '@/api/service/serviceDetail'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'
import { onMounted, ref } from '@vue/composition-api'

export default function useServiceDetail() {
  const handChangeDailog = ref(false)
  const statusOpenConfirmCreate = ref(false)
  const dialogCancel = ref(false)
  const serve_comment = ref('')
  const dialog = ref(false)
  const serve_id = ref('')
  const courseList = ref([])
  const drugList = ref([])
  const costList = ref([])
  const serviceData = ref({})
  const dataForUpdate = ref('')
  const dataUserRole = ref([])
  const dataUserList = ref([])
  const userData = ref([])
  const userPassword = ref('')
  const myFullname = ref('')
  const roleId = ref('')
  const showCost = ref(true)
  const loading = ref(true)
  const isUserComfirm = ref(false)
  const formCancel = ref(null)
  const cancelLoading = ref(false)
  const { serviceDetailGet, serviceDetailStatusUpdate } = serviceDetail

  onMounted(() => {
    getServiceDetail()
  })

  profile().then(res => {
    showCost.value = res.user_data_customers == '0'
  })

  const getServiceDetail = () => {
    serviceDetailGet(router.currentRoute.params.id).then(res => {
      if (res.serve_id) {
        serviceData.value = res
        courseList.value = res.course_list
        drugList.value = res.drug_list
        costList.value = res.servinguser
        loading.value = false
      } else {
        router.push({
          name: 'Service-Room',
        })
      }
    })
  }

  const serviceCancel = () => {
    const isFormValid = formCancel.value.validate()
    if (!isFormValid) return
    cancelLoading.value = true
    serviceDetailStatusUpdate({
      serve_id: router.currentRoute.params.id,
      serve_comment: serve_comment.value,
      lang: i18n.locale,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'delete_data_successfully',
          color: 'success',
        })
        router.push({
          name: 'Service-Room',
        })
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
        dialog.value = false
        cancelLoading.value = false
      }
    })
  }
  const print = () => {
    const routeData = router.resolve({
      name: 'printService',
      query: {
        id: String([router.currentRoute.params.id]),
      },
    })
    window.open(routeData.href, '_blank')
  }

  return {
    formCancel,
    handChangeDailog,
    statusOpenConfirmCreate,
    dialogCancel,
    serve_comment,
    dialog,
    serve_id,
    courseList,
    drugList,
    costList,
    serviceData,
    dataForUpdate,
    dataUserRole,
    dataUserList,
    userData,
    userPassword,
    myFullname,
    roleId,
    showCost,
    loading,
    cancelLoading,
    isUserComfirm,
    print,
    serviceCancel,
  }
}
