<template>
  <div>
    <v-card :loading="loading">
      <v-card-title class="px-2">
        <router-link
          class="font-weight-medium text-decoration-none me-2"
          :to="{ name: 'Service-Room'}"
        >
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                icon
                v-on="on"
              >
                <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("back") }}</span>
          </v-tooltip>
        </router-link>
        <span>
          {{ $t("list_of_courses") }}

        </span>
        <v-spacer></v-spacer>
        <span>
          {{ $t("number_user_service") }} : {{ serviceData.serve_code }}
        </span>
      </v-card-title>
      <v-simple-table dense>
        <thead>
          <tr>
            <th
              style="width:20px"
            >
              #
            </th>
            <th
              style="width:100px"
            >
              {{ $t("code") }}
            </th>
            <th
              style="width:500px"
            >
              {{ $t("use_the_course_service") }}
            </th>
            <th>
              {{ $t("receipt_number") }}
            </th>
            <th>
              {{ $t("buyer") }}
            </th>
            <th>
              {{ $t("users") }}
            </th>
            <th>
              {{ $t("service_date") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in courseList"
            :key="index"
          >
            <td
              style="width:20px"
            >
              {{ index + 1 }}
            </td>
            <td style="width:100px">
              {{ item.course_id }}
            </td>
            <td
              style="width:500px"
            >
              {{ item.course_name }}
            </td>
            <td
              style="width:150px"
            >
              <router-link
                v-if="item.order_id_pri"
                class="font-weight-medium text-decoration-none"
                :to="{name:'order-detail',params:{id:item.order_id_pri}}"
              >
                {{ item.order_id }}
              </router-link>
              <span v-else>
                {{ $t('different_branches') }}
              </span>
            </td>
            <td
              style="width:150px"
            >
              {{ item.customer_fullname }}
            </td>
            <td
              style="width:150px"
            >
              {{
                item.customer_serving
                  ? item.customer_serving
                  : item.customer_fullname
              }}
            </td>
            <td
              style="width:150px"
            >
              <ConverseDate :date="item.serving_date" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <v-card
      :loading="loading"
      class="mt-5"
    >
      <v-row>
        <v-col
          class="py-0"
          cols="12"
          md="7"
        >
          <v-card-title>
            <span>{{ $t("list_of_drugs_and_equipment_used") }}</span>
          </v-card-title>

          <v-simple-table dense>
            <thead>
              <tr>
                <th

                  style="width:20px"
                >
                  #
                </th>
                <th

                  style="width:500px"
                >
                  {{ $t("service_used") }}
                </th>

                <th>
                  {{ $t("amount") }}
                </th>
                <th>
                  {{ $t("unit") }}
                </th>
                <th class="set-font-table-th text-right">
                  {{ $t("total_price") }}
                </th>
                <th class="set-font-table-th text-right">
                  {{ $t("a_price_unit") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in drugList"
                :key="index"
              >
                <td

                  style="width:20px"
                >
                  {{ index + 1 }}
                </td>
                <td

                  style="width:500px"
                >
                  {{ item.servingdetail_drug_name }} ({{ item.servingdetail_drug_id }})
                </td>

                <td>
                  {{ item.servingdetail_drug_amount }}
                </td>
                <td>
                  {{ item.servingdetail_drug_unit }}
                </td>
                <td
                  v-if="showCost"
                  class="set-font-table-td text-right"
                >
                  {{ item.servingdetail_drug_price | formatPrice }}
                </td>
                <td
                  v-else
                  class="set-font-table-td text-right"
                >
                  xxx
                </td>
                <td
                  v-if="showCost"
                  class="set-font-table-td text-right"
                >
                  {{
                    (+item.servingdetail_drug_price *
                      +item.servingdetail_drug_amount)
                      | formatPrice
                  }}
                </td>
                <td
                  v-else
                  class="set-font-table-td text-right"
                >
                  xxx
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col
          cols="12"
          class="py-0"
          md="5"
        >
          <v-card-title>
            <span>  {{ $t("doctor_staff_free") }}</span>
          </v-card-title>
          <v-simple-table dense>
            <thead>
              <tr>
                <th>
                  #
                </th>
                <th>
                  {{ $t("name") }}
                </th>
                <th class="set-font-table-th text-right">
                  {{ $t("fee_cost") }}
                </th>
                <th>
                  {{ $t("status") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in costList"
                :key="index"
              >
                <td

                  style="width:50px"
                >
                  {{ index + 1 }}
                </td>
                <td

                  style="width:150px"
                >
                  {{ item.user_fullname }}
                </td>

                <td
                  class="set-font-table-td text-right"
                  style="width:150px"
                >
                  {{ item.servinguser_cost | formatPrice }}
                </td>
                <td

                  style="width:150px"
                >
                  {{
                    item.servinguser_type_id == 1
                      ? $t("staff")
                      : item.servinguser_type_id == 2
                        ? $t("manager")
                        : $t("doctor")
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-card-actions class="mt-6">
        <span class="font-weight-semibold">
          {{ $t('note') }} : {{ serviceData.serve_comment }}
        </span>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="print"
        >
          {{ $t("print_service_list") }}
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click="isUserComfirm = true"
        >
          {{ $t("cancel_item") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('confirm_cancellation') }}
        </v-card-title>
        <v-form
          ref="formCancel"
          @submit.prevent="serviceCancel"
        >
          <v-card-text>
            <v-textarea
              v-model="serve_comment"
              :label="$t('note_of_cancellation')"
              :rules="[required]"
              outlined
              dense
            ></v-textarea>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="cancelLoading"
              :disabled="cancelLoading"
              type="submit"
            >
              {{ $t("confirm") }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="(serve_comment = ''), (dialog = false)"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <UserConfirm
      v-model="isUserComfirm"
      @onSend="dialog = true"
    />
  </div>
</template>

<script>
import { mdiArrowLeftBold } from '@mdi/js'
import useServiceDetail from './useServiceDetail'
import { formatPrice } from '@/plugins/filters'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import UserConfirm from '@/components/basicComponents/UserConfirm.vue'
import { required } from '@/@core/utils/validation'

export default {
  components: {
    ConverseDate,
    UserConfirm,
  },
  filters: {
    formatPrice,
  },
  setup() {
    return {
      required,
      ...useServiceDetail(),
      icons: { mdiArrowLeftBold },
    }
  },

}
</script>
